.inner-banner{height: 200px; width: 100%; display: block;background: url("../images/inner-banner.jpg") no-repeat center;background-size: cover; position: relative;}
.inner-banner:after{ position: absolute; left:0; right:0; top:0; bottom:0; background: rgba(0,0,0,0.5); content: ""}

.inner-content{padding: 40px 0; background: #e7e8ea;}
.dash-stats-inner{padding: 15px 70px 15px 15px; min-height: 150px; background: #d3aa55; color: #fff; position: relative;height: calc(100% - (30px)); margin-bottom: 30px;}
.dash-stats-inner h2{padding-bottom: 20px;}
.dash-stats-inner h2,.dash-stats-inner h6{color: #fff;}
.dash-stats-inner h6{padding: 0px 10px 0 0; font-size: 18px; line-height: 20px;}
.dash-stats-inner span{line-height: 15px; display: block; font-size: 12px;}
.dash-stats-inner:after{ background: #af852b; width: 70px; content: ""; display: inline-block; position: absolute; right: 0;top: 0px; bottom: 0px;}
.dash-stats-inner .stat-icon{ position: absolute; right: 0px;background: url("../images/stat-comapny-icon.png")no-repeat center; width: 70px; height: 50px; display: block; z-index: 9; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%);}
.dash-stats-inner:before{right: 50px;top: 50%;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;
    border-color: rgba(211, 170, 85, 0);border-left-color: #d3aa55;border-width: 10px;margin-top: -10px; z-index: 9;}

.ven-stat{background: #3a3835;}
.ven-stat:before{border-color: rgba(58, 56, 53, 0);border-left-color: #3a3835;}
.ven-stat:after{ background: #292724; }
.ven-stat .stat-icon{background: url("../images/stat-venodr-icon.png")no-repeat center;}

.post-stat{background: #56607a;}
.post-stat:before{border-color: rgba(86, 96, 122, 0);border-left-color: #56607a;}
.post-stat:after{ background: #333b4e; }
.post-stat .stat-icon{background: url("../images/stat-post-icon.png")no-repeat center;}

.companies-stat{background: #3f434b;}
.companies-stat:before{border-color: rgba(63, 67, 75, 0);border-left-color: #3f434b;}
.companies-stat:after{ background: #303238; }
.companies-stat .stat-icon{background: url("../images/stat-companies-icon.png")no-repeat center;}

.applied-stat{background: #af852b;}
.applied-stat:before{border-color: rgba(63, 67, 75, 0);border-left-color: #af852b;}
.applied-stat:after{ background: #d3aa55; }
.applied-stat .stat-icon{background: url("../images/stat-applied-icon.png")no-repeat center;}

.not-applied-stat{background: #292724;}
.not-applied-stat:before{border-color: rgba(63, 67, 75, 0);border-left-color: #292724;}
.not-applied-stat:after{ background: #3a3835; }
.not-applied-stat .stat-icon{background: url("../images/stat-not-applied-icon.png")no-repeat center;}

.won-stat{background: #333b4e;}
.won-stat:before{border-color: rgba(63, 67, 75, 0);border-left-color: #333b4e;}
.won-stat:after{ background: #56607a; }
.won-stat .stat-icon{background: url("../images/stat-won-icon.png")no-repeat center;}

.lost-stat{background: #303238;}
.lost-stat:before{border-color: rgba(63, 67, 75, 0);border-left-color: #303238;}
.lost-stat:after{ background: #3f434b; }
.lost-stat .stat-icon{background: url("../images/stat-lost-icon.png")no-repeat center;}

.vendor-inner-block{background: #fff;padding: 20px 20px 40px 20px; position: relative;}
.vendor-inner-block span{display: block; padding: 0 0 12px 32px; position: relative; color: #404449;}
.vendor-inner-block span:before{content: "\f19c"; position: absolute; left: 0;top: 0;font-family: 'FontAwesome'; color: #647289;}
.vendor-list{margin: 30px 0;}
.vendor_img{width: 100%; height: 110px;margin: 0px auto 15px; background: #ccc; }
.vendor-inner-block span.company_name:before{content: "\f19c";}
.vendor-inner-block span.industry:before{content: "\f275";}
.vendor-inner-block span.website:before{content: "\f0ac";}
.vendor-inner-block span.email:before{content: "\f003";}
.vendor-inner-block span.phone:before{content: "\f10b"; font-size: 22px;}
.vendor-inner-block span a{ color: #404449;}
.vendor-inner-block span a:hover{ color: #d8b461;}
.vendor-inner-block a.view{position: absolute; left: 20px; right: 20px; bottom: 0px; background: #d8b461; text-align: center; color: #fff; border-top-left-radius: 4px; border-top-right-radius: 4px;}
.vendor-inner-block a.view i{margin-right:5px; }
.vendor-inner-block a.view:hover{background: #af852b;
    background: -moz-linear-gradient(left, #af852b 0%, #ba9138 50%, #c49b43 100%);
    background: -webkit-linear-gradient(left, #af852b 0%,#ba9138 50%,#c49b43 100%);
    background: linear-gradient(to right, #af852b 0%,#ba9138 50%,#c49b43 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af852b', endColorstr='#c49b43',GradientType=1 );}
#page-numbers li{ display: inline-block; width: 44px; height: 44px; background: #fff; text-align: center; line-height: 44px; margin: 0 8px; border-radius: 3px; }
#page-numbers{text-align: center;}
#page-numbers li:hover{ background: #d3aa55; color: #fff; cursor: pointer;}
.vendor-tab-list .btn{ color: #56607a !important;  font-size: 18px; line-height: 20px !important;font-family: 'PoppinsRegular';  text-align: left; background: none !important;box-shadow:none !important;margin: 0px;  border-bottom: 4px solid #56607a;padding: 0 0 15px 65px; height: 100%; text-transform: capitalize; width: 100%;}
.vendor-tab-list .btn.disabled{color: #999999 !important; border-bottom: 4px solid #b7b7b7;}
.vendor-tab-list .btn:focus,.vendor-tab-list .btn:not([disabled]):not(.disabled):active{ background: none !important;}
.vendot-info-detail{padding: 50px 0; width: 100%;}
.vendor-tab-list .btn:before{background: #56607a url("../images/basic_info_icon.png")no-repeat center; content: ""; position: absolute; left:0; top:-4px; width: 50px; height: 50px; display: block; border-radius: 100%;}
.vendor-tab-list .btn.disabled:before{background: #b7b7b7 url("../images/basic_info_icon.png")no-repeat center; }
.vendor-tab-list .btn.contact-info.disabled:before{background: #b7b7b7 url("../images/contact_info_icon.png")no-repeat center; }
.vendor-tab-list .btn.certificate-info.disabled:before{background: #b7b7b7 url("../images/cert_info_icon.png")no-repeat center; }
.vendor-tab-list .btn.social-info.disabled:before{background: #b7b7b7 url("../images/social_info_icon.png")no-repeat center; }
.vendor-tab-list .btn.contact-info:before{background: #56607a url("../images/contact_info_icon.png")no-repeat center;}
.vendor-tab-list .btn.certificate-info:before{background: #56607a url("../images/cert_info_icon.png")no-repeat center;}
.vendor-tab-list .btn.social-info:before{background: #56607a url("../images/social_info_icon.png")no-repeat center;}


.vn-bad-icn-block {
    padding: 8px 0 0 0;
}
.vd-badges .vn-bad-icn-block figure {
    width: 56px;
    height: 56px;
    float: left;
    padding: 0;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    margin: 0 2px 4px; padding: 3px;
}
.vd-badges .vn-bad-icn-block figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.badges-icons {
    display: inline-block;
    width: 100%;
    margin-top: 14px;
}


.vn-bad-icn-block .new-bdg {
    position: absolute;
    background: #09c6ff;
    width: 46px;
    height: 20px;
    border-radius: 19px;
    text-align: left;
    line-height: 20px;
    color: #fff;
    margin-left: 5px;
    right: -10px;
    top: 5px;
    box-shadow: 0px 3px 12px 0px rgba(20, 130, 175, 0.57);
    font-size: 12px;
    text-indent: 7px;
}
.form-tags label {
    position: relative;
}
.rbt-input-wrapper{ border: none;}
.rbt *{ border:none !important;}
.rbt {
    border-bottom: 1px solid #ced4db;
    border-radius: 2px;
    padding: 2px 6px;
}
.rbt .rbt-input-hint-container{ min-height: 35px;}
.rbt-aux{
    position: absolute;
    right: 0;
    top: -32px;
}
.rbt-aux span:first-child {
    font-size: 0;
}
.rbt-aux span:first-child:before {
    content: "Clear all";
    font-size: 17px;
    color: #d3aa55;
}
.dropdown-item span { font-size: 16px;}


/* advanced search */

.advanced-search .as-title{ width:100%; text-align: right; display: block; margin-bottom:0 !important; padding: 0 23px 12px 0; font-size: 18px;}
.advanced-search .form-field h6{ padding-bottom:20px !important; font-family: Poppins,sans-serif;
    font-size: 18px; }

.advanced-search { background: #fff;  margin-top: 85px;}
.adv-search .react-bs-table-tool-bar {  position: absolute;  width: 100%;    top: -80px;}
.dashboard-rfp-head.adv-search-title{ top: -54px;}
.advanced-search .card{ background: transparent; box-shadow:none;}
.advanced-search .cst-search{ font-size: 18px;}
.advanced-search input.form-control {  margin-bottom: 0;}
.advanced-search .form-group{}

.vi-title {
    background: #f3f3f3;
    padding: 16px 25px;
    margin-bottom: 40px;
    font-size: 20px !important;
}
.phoneblock label.active {
    -webkit-transform: translateY(0);
    transform: translateY(0);}
.phoneblock .graph-field .fa{ top:22px;}

.recipe-list-view-list li {
    background: #e7e8ea;
    width: auto;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    color: #404348;
    margin: 0 10px 10px 0;
    
    font-size: 16px;
}












