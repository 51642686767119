.menu-collapser { position: relative; width: 100%; height: 48px; padding: 0 8px; color: #FFF; font-size: 16px; line-height: 48px; background-color: #999; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box }
.collapse-button { position: absolute; right: 8px; top: 50%; width: 40px; padding: 7px 10px; color: #FFFFFF; font-size: 14px; text-align: center; background-color: #0E0E0E; border-radius: 2px; cursor: pointer; transform: translate(0, -50%); -o-transform: translate(0, -50%); -ms-transform: translate(0, -50%); -moz-transform: translate(0, -50%); -webkit-transform: translate(0, -50%); box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box }
.collapse-button:hover, .collapse-button:focus { color: #FFF; background-color: #040404 }
.collapse-button .icon-bar { display: block; height: 2px; width: 18px; margin: 2px 0; background-color: #F5F5F5; border-radius: 1px }
ul.slimmenu { width: 100%; margin: 0; padding: 0; list-style-type: none; display: block !important; }
ul.slimmenu:before, ul.slimmenu:after { content: ''; display: table }
ul.slimmenu:after { clear: both }
ul.slimmenu.collapsed li { display: block; width: 100%; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box }
ul.slimmenu.collapsed li ul { position: static; display: none; -webkit-border-radius:0; -moz-border-radius:0; -ms-border-radius:0; -o-border-radius:0; border-radius:0;}
ul.slimmenu.collapsed li a { display: block; font-family: 'PoppinsRegular'; }
ul.slimmenu.collapsed li .sub-toggle { height: 40px }
ul.slimmenu li { float:left; margin:0 10px; position:relative;}
ul.slimmenu li a{padding: 0px;}
ul.slimmenu li.has-submenu { padding-right: 0;}
ul.slimmenu li ul { margin: 0; list-style-type: none }
ul.slimmenu li ul li { border-top:1px solid #4478a5; width:100%; text-align:left; background:none; padding:0; margin:0;}
ul.slimmenu li ul li:first-child{ border:0;}
ul.slimmenu li ul { position: absolute; left: 0; top: 100%; z-index: 999; margin-top: 0 !important; display: none; background:#4e8abe; min-width: 180px; max-width:180px; height:auto; padding:0; border:0; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.09); -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.09); -webkit-border-radius:3px; border-radius:3px;}
ul.slimmenu li ul li ul { position: absolute; left: 100%; top: 0; z-index: 999; display: none; width: 100%; }
ul.slimmenu li a { font-size:18px; line-height:30px; color:#56607a; position:relative; margin: 7px 0; display: inline-block;}
ul.slimmenu li a:hover,ul.slimmenu li.active a, ul.slimmenu li.has-submenu:hover a { color:#d3aa55; }
ul.slimmenu li.has-submenu a:hover, ul.slimmenu li.has-submenu a:focus, ul.slimmenu li.has-submenu a.active{ -webkit-box-shadow:none; box-shadow:none;}
ul.slimmenu li.has-submenu ul li a{ display:block; margin:0; color:#fff !important; font-family: 'PoppinsRegular'; padding:11px 10px 9px 15px; white-space:normal; font-size:14px; line-height:20px; text-transform:none; text-align:left; background:none;}
ul.slimmenu li.has-submenu ul li a:hover, ul.slimmenu li.has-submenu ul li a:focus, ul.slimmenu li.has-submenu ul li a.active{ background:#4478a5; color:#fff; -webkit-box-shadow:none; box-shadow:none;}
ul.slimmenu li .sub-toggle { margin:-24px -20px 0 0; position: absolute; right: 0; top: 0; z-index: 999; width: 15px; height: 100%; text-align: right; cursor: pointer }
ul.slimmenu li .sub-toggle:before { content:""; display: inline-block; height: 50px; margin: 0; vertical-align: middle }
ul.slimmenu li .sub-toggle i { display: inline-block; color: #000; font-size: 18px; line-height: 16px; height:12px; vertical-align: middle; }
.menu-collapser { display: none !important; }
ul.slimmenu li ul:before{ position: absolute; left: 20px; top: -8px; width: 15px; height: 10px; content: "\f0d8"; font-family: 'FontAwesome'; display: block; margin:0; font-size: 20px; line-height: 10px; color: #4e8abe;}
	
@media all and (min-width:1200px) {
.c-menu__close, .c-button { display: none; }
}
