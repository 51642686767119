
/* RT 09-11-19 */
@media only screen and (min-width:768px) and (max-width:1199px){

    ul.slimmenu li a{padding: 0 0 0 20px !important; margin: 0px !important; line-height: 45px !important; display: block; color: #fff !important; font-size: 18px !important; background: none !important; text-align: left !important; border:0px !important;box-shadow:none !important; text-transform: capitalize;border-bottom: 1px solid #1d3244 !important;}
    ul.slimmenu li a:hover{background: #d3aa55 !important; color: #fff !important}
    .c-menu__close{background-color: #1a1b1b !important;}
    ul.slimmenu li{float:none; text-align: left; margin: 0px;}

    /* RT 09-13-19 */
    .react-bs-table-container table.table td:last-child,.react-bs-table-container table.table th:last-child {width: 260px;}
    .graph-block select, .graph-block input {font-size: 14px;}

    /* RT 09-16-19 */
    .vendor-tab-list{margin-top:70px;}
    .vendor-tab-list .btn {padding: 0 0 15px 0;font-size: 16px;text-align:center;}
    .vendor-tab-list .btn:before {left: 50%;transform: translatex(-50%);top:-60px;}

}    


@media only screen and (min-width:768px) and (max-width:1024px){
    ul.slimmenu.dropmenu{position: inherit; right:0;}
    .about-images .about-img{width: 85%; height: auto;}
    .about-images .about-img1{width: 90%; height: auto;}
    footer{font-size:12px;}
    .m-120{padding: 60px 0 }
    
    .slide-pane{width:65% !important;}
    .dashboard-rfp-head span{padding: 0 0 0 36px;margin-left: 20px;font-size: 18px;}
    .dashboard-rfp-head h6{font-size: 16px;}
    .react-bs-container-body{overflow-x: scroll;}
    .react-bs-container-header table,.react-bs-container-body table{width: 150%;}
    .mobile-nav{display:block;}
    .carousel-item{height: auto;}

    /* RT 09-12-19 */
    .dashboard-rfp-head span.all{padding: 5px 10px 5px 30px;background-size: 20px auto !important;}

}


@media only screen and (max-width:991px) {
/* RT 09-12-19 */
.dashboard-rfp-head span{background-size: 20px auto !important;}
.react-bs-table-tool-bar .col-md-6 {flex: 0 0 100%;max-width: 100%;}
.graph-block .graph-date .col-md-2 {flex: 0 0 22.666667%; max-width: 22.666667%;}
.vendot-info-detail {padding: 20px 0;}
.dashboard-rfp-head.adv-search-title {top: -70px;}

/* RT 09-16-19 */
.dashboard-rfp-icon.dashboard-rfp-head{position: relative !important;left:0 !important;margin: 0px 0 25px 0;}
.dashboard-rfp-head.dashboard-rfp-icon h6{padding: 0;}
#rfp_back {margin-right: 0;}
.react-bs-table-pagination .col-md-6 {max-width:100%; flex: 0 0 100%;text-align: center;}
.react-bootstrap-table-page-btns-ul {float: inherit;display: inline-block;margin-top:50px;}
.react-bootstrap-table-page-btns-ul li{display: inline-block;}
.page-show {top:50px;left:50%;width: 100%;}

/* RT 09-17-19 */
.dashboard-rfp-head{top:10px;}
.react-bs-table-container .react-bs-table-search-form {margin-top: 10px;}
.advanced-search .as-title {padding: 0 23px 40px 0;position: relative; top: 30px;}



}


@media only screen and (max-width:767px) {
    /* .dashboard-rfp-head{position: absolute !important;left: 15px !important;} */

    .c-button{top: -36px;}
    .carousel-item{height: auto;}
    ul.slimmenu.dropmenu{position: inherit; right:0;}
    header{background: #fff; top: 0; height: 70px; }
    header .col-md-8{position:inherit;}
    .inner-header{display: contents;}
    .logo{margin: 10px 0;}
    .logo img{width:100px;}
    ul.slimmenu li{float:none; display: inline-block; width:100%; text-align: left;}
    .carousel{ margin-top:70px; }
    .banner-content h2{ font-size: 20px; padding: 0 0 15px 0 ;line-height: 20px;}
    .banner-content h6{font-size: 14px; padding: 0 0 15px 0 ; line-height: 16px;}
    .banner-content{bottom: 0px;}
    .carousel-indicators{display: none;}
    .banner-content .col-md-8{margin: 0 10px;}
    .m-120{padding: 40px 0 }
    .about-images .about-img1{ display: none;}
    .about-images .about-img{ width: 100%; height: auto; float: left; margin-bottom:25px; }
    .stats-block{padding: 30px 0; background-size:cover; }
    .partner-img1 img{width: 100%; height: auto;}
    footer{padding: 10px 0; font-size: 13px; text-align: center; line-height: 16px;}
    footer .col-md-6+.col-md-6{text-align: center;}
    .about-block h2.displ{ display: none;}
    .about-block .about-images h2{ display: block;}
    .about-images{float: none; min-height: inherit;}
    .c-menu__close{background-color: #1a1b1b !important;}
    ul.slimmenu li{float:none; text-align: left; margin: 0px;}
    ul.slimmenu li a{padding: 0 0 0 20px !important; margin: 0px !important; line-height: 45px !important; display: block; color: #fff !important; font-size: 18px !important; background: none !important; text-align: left !important; border:0px !important;box-shadow:none !important; text-transform: capitalize;border-bottom: 1px solid #1d3244 !important;}
    ul.slimmenu li a:hover{background: #d3aa55 !important; color: #fff !important}
    .slide-pane{width:90% !important; position: fixed ; right:0; top:0;}
    .slide-pane__content .login-block{padding: 0px;}
    .form-field input, .form-field select, .form-field textarea { font-size: 14px;}
    .slide-pane__content{padding:24px 20px;}
    .react-tabs li{width:46%; margin: 0px 5px; padding: 0px;}
    .mobile-nav{display:block;}
    .user-header{ position: fixed; top: 0; right: 0;}
    .user-header .notification-icon{padding: 12px 0; margin:0px;}
    .user-header .dropdown{  padding: 10px 23px !important; margin: 0 0 0 10px; right:0px;}
    .notification-icon{ position: relative;top:0;}
    .react-bs-container-body{overflow-x: scroll;}
    .react-bs-container-header table,.react-bs-container-body table{width: 250%;}
    .dashboard-rfp-head{position: relative; top:inherit; margin:0px 0 0 0; left:0}
    .dashboard-rfp-head h6{ padding: 0px;}
    .dashboard-rfp-head .fav-block{margin:0px;}
    .rfpviewdetail .white-bg{background: #fff;padding-left:10px; padding-right:10px;}
    .vendor-detail h6, .vendor-detail h6 a, .vendot-info-detail h6 a{word-break: break-all;}
    .vendor-tab-list .btn:before{top:-10px;}
    .vendor-tab-list span{margin-bottom: 30px;}
    /* .pagination{display: block; width: 100%; text-align: center; margin-top:20px;} */
    /* .pagination li{display: inline-block;} */
    /* .page-show{left:90px; transform: inherit;} */
    h1{font-size: 30px;}
    h2{font-size: 26px}
    h3{font-size: 24px;}
    h4{font-size: 22px;}
    h5{font-size: 20px;}
    h6{font-size: 18px;}
    .dash-stats-list{width:50%;padding:0px 10px;}
    .dash-stats-inner{margin-bottom: 20px;}
    .dash-stats-inner h6{font-size: 15px;}
    .react-tabs li em{font-size: 14px;}
    .graph-field{margin-top:15px;}
    .dropdown .dropdown__trigger em{width:130px;}


    /* RT 09-11-19 */
    .left-block{margin-bottom:30PX;}
    .partner-img.partner-img1{margin-bottom:0;}

    /* RT 09-13-19 */
    .graph-block .graph-date .col-md-2 {flex: 100%;max-width: 100%;}
    .dashboard-rfp-head h6 {padding:5px 0 15px 0 !important;display: block;}
    .dashboard-rfp-head .all{margin-left:0;}
    .vendot-info-detail .marg {margin-bottom: 15px;}
    .vendot-info-detail h6, .vendor-detail h6 {font-size: 14px;line-height:22px;}
    
    
    /* RT 09-16-19 */
   .ex-title{font-size: 26px;line-height: 32px;display: block;margin-top: 10px;}
   .vendor-tab-list .btn:before {width: 40px;height: 40px;}
   .vendor-tab-list .btn {padding: 0 0 20px 55px;font-size:16px;}
   .vendor-detail { margin-top: 20px;}
   .btn.btn-sm {padding: 10px 20px;}

   /* RT 09-17-19 */
    .dashboard-rfp-head.adv-search-title{position: absolute;margin: 0 0 0 20px;}
    .com-search-title{width:100%;top: -75px;}
    .advanced-search .form-field h6 {font-size: 16px;}

    /* RT 09-18-19 */
    .md-form .form-control{margin-bottom:0;}
    .input-field.fileupload{margin-bottom: 15px;}
    .btn.back-btn{padding: 10px 15px 10px 30px !important;}
    .back-btn::before {left: 8px;top: 10px;font-size: 16px;}
    .text-btn-div{position: relative;}
    .text-btn-div .btn.back-btn {
        position: absolute;
        top: -50px;
        background: none;
        box-shadow: inherit;
        color: #2b465e;
        border-bottom: 1px solid #ddd;
        width: 100%;
        text-align: right;
    }
    .text-btn-div .back-btn::before{color:#2b465e;right:60px;}

    /* RT 09-19-19 */
    .dashboard-rfp-head span {font-size: 14px;padding: 5px 15px 5px 40px;}
    .dashboard-rfp-head span.all{background-size: 20px auto !important;}
    table.table th, table.table td {padding: 15px 10px 15px 10px;}
   
}

@media only screen and (min-width:650px) and (max-width:767px){
    .banner-content{bottom: 100px;}

}

@media only screen and (max-width:575px) {
    /* .dashboard-rfp-head {position: absolute !important;} */
    .dashboard-rfp-head.adv-search-title {top: -75px !important;}
    .advanced-search{padding: 5px 0px 0px 0px !important;}

    /* RT 09-16-19 */
    .ex-title,.sub-ex-title{font-size: 22px;line-height: 26px;}
    
    .btn.btn-sm {margin-left: 10px;}

    /* RT 09-17-19 */
    .pagination li a {line-height: 35px;width: 35px;margin:0 0 10px 5px;}

    /* RT 09-18-19 */
    .header-right .dropdown__content ul li a {padding:0 10px;}
    h3 {font-size: 20px;line-height: 26px;}

    .success-green,.error-red {position: absolute;top: 50px;left: 50%;transform: translateX(-50%);}
    .dropdown .dropdown__trigger em span {font-size:14px;}
    
    

}

@media only screen and (max-width:536px) {
    /* .dashboard-rfp-head.adv-search-title {top: -85px !important;} */
    /* .dashboard-rfp-list .dashboard-rfp-head h6{display: block;margin: 15px 0px;} */
}

@media only screen and (max-width:480px) {
    .slide-pane{width: 100% !important;}
    .dash-stats-list{width:100%;padding: 0px 15px;}
    .dash-stats-inner{margin-bottom: 30px;}

    /* RT 09-17-19 */
    .user-header .dropdown{padding: 10px 10px 10px 5px !important; margin: 0;}
    
    /* RT 09-18-19 */
    .dropdown .dropdown__trigger em{width:90px;}
    .dropdown__trigger span {padding: 0 20px 0 15px;}
    .header-right .dropdown__content ul li a i{margin-right: 10px;}
    .header-right .dropdown__content ul li a{font-size: 12px;}

    /* RT 09-19-19 */
    .header-right .dropdown__content.notification-list ul li a{padding: 0 70px 0 0px;margin-left:10px;}


    
}

@media only screen and (max-width:425px) {
    .dashboard-rfp-head span{margin-left: 0px;}
}

