.style-guide{ background:#f5f5f5;}
.style-guide .content-block{ padding: 50px; width: 100%;}
.style-guide .style-block{ background:#fff; position: relative; margin: 20px; border: 1px solid #E6E9ED; box-shadow: 0 0 10px rgba(0,0,0,0.1); padding: 30px; -ms-flex: 100%; flex: 100%; max-width: 100%;}
/* .style-guide .style-block code { height:calc(100% - 70px); border: 1px solid #ccc; display: block; padding: 20px; margin: 0; font-size: 13px; line-height: 20px; color: #333; word-break: break-all;word-wrap: break-word; background-color: #f5f5f5; -webkit-border-radius: 4px; -moz-border-radius: 4px; -ms-border-radius: 4px; -o-border-radius: 4px; border-radius: 4px;} */
.style-guide .style-block code {  display: inline-block; padding: 0 0 0 15px; margin: 0; font-size: 16px; line-height: 20px; color: #f44336; word-break: break-all;word-wrap: break-word;}
.style-guide .style-block .style-title { display: block; text-align:left; min-height:inherit; font-weight: 600; padding: 5px 0 10px 0; margin: 0 0 20px 0; color: #626879; font-size: 24px; line-height: 30px; border-bottom: 1px solid #d3d7da;}
.style-guide .style-block .style-title small { font-size: 16px; color: #888; padding: 0 0 0 10px;}
.style-guide .style-block h1, .style-guide .style-block h2,
.style-guide .style-block h3, .style-guide .style-block h4,
.style-guide .style-block h5, .style-guide .style-block h6{ padding:0 0 20px 0; display: inline-block; min-width: 150px;}
.paragraph-block p{ display:inline-block;}
.color-blocks, .background-blocks{ font-weight: 500; font-size:24px;}
.color-blocks div{ padding:15px 0;}
.color-blocks div em{ display:inline-block; min-width: 200px; font-style: normal;}
.background-blocks div{ margin:0 0 10px 0; min-height:80px; text-align:center; line-height:80px; min-width: 450px; display: inline-block;}
.btn-blocks .btn{ margin-right:10px; min-width: 150px;}
.btn-blocks em{ display: block; font-style: normal; padding: 0 0 20px 0;}
.bg-dark code{ color: #fff !important;}
.example{ text-align: left !important; padding: 20px 30px !important; line-height: 30px !important; background: #eee; float: right; min-width: 480px !important;}
.example em{ font-size: 20px !important; line-height: 30px !important; color: #f44336; font-style: normal; padding: 0 0 5px 0; display: block;}
.example code{ display: block !important; padding: 0 0 5px 0 !important; color: #555 !important;}
.list-blocks ul{ display: inline-block; min-width: 250px; vertical-align: top;}

.style-guide .page-title{ position: relative; width: 100%; top:0; margin: 0 0 20px 0;}
.style-guide .page-title h1{ padding: 0;}
.tool-tips .btn{ max-width: 110px; font-size: 14px; padding: 10px 0; text-transform: none; min-width: 110px; margin: 4px 0 4px 0;}
.icon-btns .btn-icon, .icon-btns code{ vertical-align: top; margin-bottom: 20px !important;}
