body { font-family: 'Poppins', sans-serif; font-size: 14px; line-height: 24px; color: #555; font-weight:normal;}

/*---Globel styles---*/
h1, h2, h3, h4, h5, h6 { font-family: 'PoppinsMedium'; font-weight: normal; color: #3a3835; padding: 0 0 30px 0; margin:0;}
h1 { font-size: 40px; line-height: 40px;color:#56607a;}
h2 { font-size: 34px; line-height: 40px;}
h3 { font-size: 30px; line-height: 34px;}
h4 { font-size: 26px; line-height: 30px;}
h5 { font-size: 24px; line-height: 30px;}
h6 { font-size: 20px; line-height: 24px;}
p { font-size: 14px; line-height: 24px; color: #555; padding: 0 0 20px 0; margin:0;}
.page-title h1{display: inline-block; position: relative;}
.page-title h1:after{position: absolute; bottom: -15px;width: 50px; height: 5px; background: #d3aa55; display: inline-block; content:""; left:50%; transform: translateX(-50%);-webkit-transform: translateX(-50%);}
a { -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; text-decoration: none; outline: none; color: #af852b;}
a:not([href]):not([tabindex]){ color: #af852b}
a:hover, a:focus { text-decoration: none; outline: none; color: #3f434b;}
a img { border: none;}
ul { list-style: none; padding: 0; margin: 0;}
b, string, .bold{ font-family: 'PoppinsMedium';}
.mar-top{margin-top:50px !important;}
label, input, textarea, button, select { font-family: 'Poppins', sans-serif; font-weight:400 !important; outline: none;}
input,select,textarea{width:100%;}
a.und{text-decoration: underline !important; text-transform: uppercase;}
.fleft { float: left !important;}
.fright { float: right !important;}
.clear { clear: both; position: relative;}
.clear:after { display: block; clear: both; content: "";}
.line{ width:100%; display:block; height:2px; background:#e4e7ed; margin:20px 0;}
.no-mr{ margin: 0 !important;}

.color-yellow{ color:#d3aa55 !important;}
.color-dark-blue{ color:#56607a !important;}
.color-dark-gray{ color:#3a3835 !important;}
.color-gray{ color:#999999 !important;}

.bg-yellow{ background-color:#d3aa55 !important;}
.bg-dark-blue{ background-color:#56607a !important;}
.bg-dark-gray{ background-color:#3a3835 !important;}
.bg-gray{ background-color:#999999 !important;}

.bg-yellow, .bg-dark-blue, .bg-dark-gray, .bg-gray{ color:#fff !important;}

.list-arrow, .list-bullet, .content-block ul{ padding: 0 0 10px 10px}
.list-arrow li, .list-bullet li, .content-block ul li { position: relative; padding: 0 0 15px 20px; font-size: 18px; line-height: 20px;}
.list-arrow li:before, .list-bullet li:before, .content-block ul li:before { position: absolute; left: 0; top: 0; font-family: 'FontAwesome';  color: #56607a; font-size: 18px; line-height: 20px; display: block;}
.list-arrow li:before, .content-block ul li:before{ content: "\f0da";}
.list-bullet li:before {  top: 5px !important; width: 9px; height: 9px; background: #56607a; content:"" !important; border-radius: 100%;}

.m-120{padding: 80px 0;}
.btn{  z-index: 9; outline:none; display:inline-block; position: relative; color: #fff; margin: 0 0 10px 0; font-weight: normal; text-align:center; font-weight: 500; border: 0; font-size: 16px; line-height:20px; text-transform:uppercase; padding: 15px 50px 15px 50px; -webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0); -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;
    background: #dab25d;
    background: -moz-linear-gradient(top, #dab25d 0%, #bb9138 50%, #b1872d 100%);
    background: -webkit-linear-gradient(top, #dab25d 0%,#bb9138 50%,#b1872d 100%);
    background: linear-gradient(to bottom, #dab25d 0%,#bb9138 50%,#b1872d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dab25d', endColorstr='#b1872d',GradientType=0 );}
.btn i{margin: 0 0 0 10px; padding: 0px !important; font-size: 18px;}
.btn:hover, .btn:focus{ color:#fff !important;background: #af852b;
    background: -moz-linear-gradient(left, #af852b 0%, #ba9138 50%, #c49b43 100%);
    background: -webkit-linear-gradient(left, #af852b 0%,#ba9138 50%,#c49b43 100%);
    background: linear-gradient(to right, #af852b 0%,#ba9138 50%,#c49b43 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af852b', endColorstr='#c49b43',GradientType=1 );}


 .btn.btn-white-bg{ background: none; border: 1px solid #56607a; color: #56607a;}   
 .btn.btn-white-bg:hover,.btn.btn-white-bg:focus{ background: #56607a; color: #fff;}
.slimmenu li a.btn.btn-white-bg{background: none; border: 1px solid #d2aa54; color: #d2aa54;}
.slimmenu li a.btn.btn-white-bg:hover,.slimmenu li a.btn.btn-white-bg:focus{ background: #d2aa54; color: #fff;}
.btn.btn-lg{ padding: 18px 60px;line-height: 16px;}
.btn.btn-md{ padding: 12px 40px;line-height: 16px;}
.btn.btn-sm{ padding: 15px 30px; font-size: 14px; line-height: 15px;}
.btn i{ padding: 0 8px 0 0;}
input[type="submit"].btn{ background: #dab25d;
    background: -moz-linear-gradient(top, #dab25d 0%, #bb9138 50%, #b1872d 100%);
    background: -webkit-linear-gradient(top, #dab25d 0%,#bb9138 50%,#b1872d 100%);
    background: linear-gradient(to bottom, #dab25d 0%,#bb9138 50%,#b1872d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dab25d', endColorstr='#b1872d',GradientType=0 );-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; -webkit-transition-timing-function: ease-out; transition-timing-function: ease-out;}
input[type="submit"].btn:hover{ background: #af852b;
    background: -moz-linear-gradient(left, #af852b 0%, #ba9138 50%, #c49b43 100%);
    background: -webkit-linear-gradient(left, #af852b 0%,#ba9138 50%,#c49b43 100%);
    background: linear-gradient(to right, #af852b 0%,#ba9138 50%,#c49b43 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af852b', endColorstr='#c49b43',GradientType=1 );}

.form-control{ width: 100%; height:50px; font-size: 13px; color:#8c929f; border:0px ; border-radius: 0px; padding: 0px; border-bottom: 1px solid #cacad2; -webkit-box-shadow:none !important; -moz-box-shadow:none !important; -ms-box-shadow:none !important; -o-box-shadow:none !important; box-shadow:none !important; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none; -o-appearance:none; appearance:none;}
select.form-control:not([size]):not([multiple]){ height:50px; position:relative; background:url(../images/drop-arrow.png) no-repeat right 0px center; padding:0 30px 0 0 !important;}
textarea.form-control{ height:120px;}

.form-check{ padding: 0; margin: 0 20px 10px 0; position: relative;}
.form-check input[type="checkbox"], .form-check input[type="radio"]{ opacity: 0; float: left; margin: 0; top:0; left:0; height: 27px; width: 27px; display: inline-block; z-index: 1; cursor: pointer; position:absolute;}
.form-check input[type="checkbox"] + label,
.form-check input[type="radio"] + label{ display: inline-block; padding: 0 0 0 40px; color: #313131; font-weight:500 !important; line-height: 27px; position: relative; top:inherit;  margin: 0; cursor: pointer; -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; font-size:18px;}
.form-check input[type="checkbox"] + label:before, .form-check input[type="checkbox"] + label:after,
.form-check input[type="radio"] + label:before, .form-check input[type="radio"] + label:after{ width: 27px; height: 27px; line-height: 27px; font-size: 12px; border: 1px solid #ccc; text-align: center; color: #4e8abe; display: block; position: absolute; left: 0; top: 0; background: #fff; content: ""; font-family: 'FontAwesome'; -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; -webkit-border-radius:4px; -moz-border-radius:4px; -ms-border-radius:4px; -o-border-radius:4px; border-radius:4px;}
.form-check input[type="checkbox"] + label:after, .form-check input[type="radio"] + label:after{  background: #d3aa55; border-color:#d3aa55; opacity: 0; content: "\f00c"; color: #fff; font-size: 16px;}
.form-check input[type="radio"] + label:after{ width: 13px; height: 13px; top: 7px; left: 7px; content: "";}
.form-check input[type="radio"] + label:before,
.form-check input[type="radio"] + label:after{ -webkit-border-radius: 100%; -moz-border-radius: 100%; -ms-border-radius: 100%; -o-border-radius: 100%; border-radius: 100%;}
.form-check input[type="checkbox"]:checked + label:after,
.form-check input[type="radio"]:checked + label:after{ opacity: 1;}
.form-check.inline{ display: inline-block;}

.form-control::-webkit-input-placeholder, .form-control::-webkit-input-placeholder { color:#ff0000;}
.form-control:-moz-placeholder, .form-control:-moz-placeholder { color:#ff0000;}
.form-control::-moz-placeholder, .form-control::-moz-placeholder { color:#ff0000;}
.form-control:-ms-input-placeholder, .form-control:-ms-input-placeholder {color:#ff0000;}



.form-group{ position: relative; margin: 0px 0 35px 0;}
.form-group .form-focus:before, .form-group .form-focus:after,
.form-group .form-focus i:before, .form-group .form-focus i:after,
.cs-select:before, .cs-select:after,
.cs-select .cs-placeholder:before, .cs-select .cs-placeholder:after{ content: "";  position: absolute; top: 0; left: 0; background-color: #3b5dc5; transition: 0.3s;}
.form-group .form-focus:before, .form-group .form-focus:after,
.cs-select:before, .cs-select:after{  width: 0; height: 2px;}
.form-group .form-focus i:before, .form-group .form-focus i:after,
.cs-select .cs-placeholder:before, .cs-select .cs-placeholder:after { width: 2px; height: 0;}
.form-group .form-focus:after, .form-group .form-focus i:after,
.cs-select:after, .cs-select .cs-placeholder:after{ top: auto; bottom: 0; left: auto; right: 0;}
.form-group .form-control:hover ~ .form-focus:before, .form-group .form-control:hover ~ .form-focus:after,
.cs-select:hover:before, .cs-select:hover:after{ width: 50%;}
.form-group .form-control:hover ~ .form-focus i:before, .form-group .form-control:hover ~ .form-focus i:after,
.cs-select:hover .cs-placeholder:before, .cs-select:hover .cs-placeholder:after{ height: 50%;}
.form-group .form-control:focus ~ .form-focus:before, .form-group .form-control:focus ~ .form-focus:after,
.cs-select:focus:before, .cs-select:focus:after{ width: 100%;}
.form-group .form-control:focus ~ .form-focus i:before, .form-group .form-control:focus ~ .form-focus i:after,
.cs-select:focus .cs-placeholder:before, .cs-select:focus .cs-placeholder:after{ height: 100%;}
.form-group .form-control:hover, .form-group .form-control:focus{ border-color: #d3aa55;}
.form-group .form-control:disabled{ background:#f3f3f3; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; -ms-box-shadow: none !important; -o-box-shadow: none !important; -box-shadow: none !important;}
.form-field .form-control.form-success{ border-color: #74c125;}
.form-group .form-control.form-error{ border-color: #e44144;}
.form-group .form-control:disabled ~ .form-focus:before, .form-group .form-control:disabled ~ .form-focus:after,
.form-group .form-control:disabled ~ .form-focus i:before, .form-group .form-control:disabled ~ .form-focus i:after{ background-color: #e4e7ed;}
.form-group label { position: absolute; left: 0; margin: 0; top: -25px; width: 100%;  font-size: 14px; line-height: 20px; color: #555; font-weight: 500 !important;}
.form-block .btn{ min-width: 170px; margin: 0 15px 15px 0;}
.cs-select:before{ top:-2px;}
.cs-select:after{ bottom: -2px;}
.custom-control-label{ line-height: 24px;}

.file-upload { position:relative;}
.file-upload input[type='file'] { opacity:0;}
.file-upload .file-name { width: 100%; height:50px; position: absolute; top: 0; left: 0; font-size:13px; line-height: 50px; color: #8f8f8f; pointer-events: none; padding: 0 .75rem; border: 2px solid #e4e7ed;}
.file-upload .btn-upload{ position: absolute; right: 0; top: 0; height: 44px; color: #fff; cursor: pointer; outline: none; padding: 10px 30px 10px 75px; margin: 3px 3px 0 0;text-transform: none; font-weight: 300 !important; font-size: 14px;border: 0; }
.file-upload .btn-upload, .file-upload .file-name{ -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px; border-radius: 3px;}

.image-fill { position: relative; overflow:hidden;}
.image-fill img { width: 100%; height: 100%; -webkit-object-fit: cover; -moz-object-fit: cover; -ms-object-fit: cover; -o-object-fit: cover; object-fit: cover; transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out;}

.parallax-bg { background-attachment: fixed; background-size: cover; background-color:#555;}

.overlay{ background:#000; opacity:0.8; position:absolute; left:0; top:0; width:100%; height:100%; display:block; z-index:9;}

.title{ text-transform:uppercase; text-align:center; font-family: 'AntonioBold'; color:#222323; font-size:60px; line-height:60px; padding:0 0 25px 0; margin:0 0 60px 0; position:relative; }
.title:before{ width:80px; height:5px; background:#4e8abe; content:""; position:absolute; left:50%; bottom:0; -webkit-transform:translateX(-50%); -moz-transform:translateX(-50%); -ms-transform:translateX(-50%); -o-transform:translateX(-50%); transform:translateX(-50%);}
.title-left{ text-align:left;}
.title-left:before{ left:0; -webkit-transform:translateX(0); -moz-transform:translateX(0); -ms-transform:translateX(0); -o-transform:translateX(0); transform:translateX(0);}
.sub-title{ text-transform:uppercase; text-align:left; font-family: 'AntonioBold'; color:#636465; font-size:24px; line-height:30px; padding:0 0 15px 0; margin:0 0 20px 0; position:relative; display:inline-block;}
.sub-title:before, .sub-title:after{ width:50px; height:5px; background:#4e8abe; content:""; position:absolute; left:0; bottom:0; z-index:2;}
.sub-title:after{ width:100%; height:1px; background:#000000; z-index:1;}

.accordion .card{ border:0px;}
.accordion .card-header{ background:none; color: #000;font-family: 'PoppinsMedium'; font-size:16px; position: relative;}
.accordion .card-header:after{ position: absolute;transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out; right: 10px; top: 15px; color: #000000; content:"\f078"; border:1px solid #eeeeee;font-family: 'FontAwesome';width:24px; height: 24px; border-radius: 100%; text-align: center; line-height: 22px; font-size: 14px;}
.accordion .card{ background: #eeeeee; position: relative;}
.accordion .card:before{ content:""; position: absolute; left: 0; top: 0px; background: #d3aa55; width: 5px; height: 100%;}
.accordion .card .card-header:after{ background: #d3aa55; border: 1px solid #d3aa55; color: #fff;}
.accordion .card .card-header{ border-bottom: 0px;}
.accordion .card-body{padding: 20px 30px;}
.accordion .card-header:hover:after{transform: rotate(180deg);-ms-transform: rotate(180deg); -webkit-transform: rotate(180deg);}

.tabs-block .nav-item:before{ content:none; }
.tabs-block .nav-item{padding:0px;}
.tabs-block .nav{ padding: 0px;}
.tabs-block .tab-content{ background: #eeeeee; padding: 20px;}
.tabs-block .nav-tabs .nav-link{ padding:15px 70px; position: relative; border:0px; color: #d3aa55;font-family: 'PoppinsMedium';}
.tabs-block .nav-tabs .nav-link.active{background: #eeeeee;}
.tabs-block .nav-tabs .nav-link:before{width: 0;height: 0;border-style: solid;border-width:49px 45px 0 58px;border-color: #ffffff transparent transparent transparent;content:""; position: absolute; left:-32%; top:0px;}
.tabs-block .nav-tabs .nav-link:after{width: 0;height: 0;border-style: solid;border-width: 49px 55px 0 48px;border-color: #ffffff transparent transparent transparent;content:""; position: absolute; right:-29%; top:0px;}
/*---Font Face Start---*/
@font-face {
    font-family: 'PoppinsLight';
    src: url('fonts/PoppinsLight.eot');
    src: url('fonts/PoppinsLight.eot') format('embedded-opentype'), url('fonts/PoppinsLight.woff2') format('woff2'), url('fonts/PoppinsLight.woff') format('woff'), url('fonts/PoppinsLight.ttf') format('truetype'), url('fonts/PoppinsLight.svg#PoppinsLight') format('svg');
}
@font-face {
    font-family: 'PoppinsRegular';
    src: url('fonts/PoppinsRegular.eot');
    src: url('fonts/PoppinsRegular.eot') format('embedded-opentype'), url('fonts/PoppinsRegular.woff2') format('woff2'), url('fonts/PoppinsRegular.woff') format('woff'), url('fonts/PoppinsRegular.ttf') format('truetype'), url('fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
}
@font-face {
    font-family: 'PoppinsMedium';
    src: url('fonts/PoppinsMedium.eot');
    src: url('fonts/PoppinsMedium.eot') format('embedded-opentype'), url('fonts/PoppinsMedium.woff2') format('woff2'), url('fonts/PoppinsMedium.woff') format('woff'), url('fonts/PoppinsMedium.ttf') format('truetype'), url('fonts/PoppinsMedium.svg#PoppinsMedium') format('svg');
}
@font-face {
    font-family: 'PoppinsBold';
    src: url('fonts/PoppinsBold.eot');
    src: url('fonts/PoppinsBold.eot') format('embedded-opentype'), url('fonts/PoppinsBold.woff2') format('woff2'), url('fonts/PoppinsBold.woff') format('woff'), url('fonts/PoppinsBold.ttf') format('truetype'), url('fonts/PoppinsBold.svg#PoppinsBold') format('svg');
}
@font-face {
    font-family: 'PoppinsSemiBold';
    src: url('fonts/PoppinsSemiBold.eot');
    src: url('fonts/PoppinsSemiBold.eot') format('embedded-opentype'), url('fonts/PoppinsSemiBold.woff2') format('woff2'), url('fonts/PoppinsSemiBold.woff') format('woff'), url('fonts/PoppinsSemiBold.ttf') format('truetype'), url('fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
}
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.2/css/font-awesome.css";

/*---Fontawsome---*/
@font-face { font-family: 'FontAwesome';
    src: url('fonts/fontawesome-webfont.eot?v=4.6.2');
	src: url('fonts/fontawesome-webfont.eot?#iefix&v=4.6.2') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff2?v=4.6.2') format('woff2'), url('fonts/fontawesome-webfont.woff?v=4.6.2') format('woff'), url('fonts/fontawesome-webfont.ttf?v=4.6.2') format('truetype'), url('fonts/fontawesome-webfont.svg?v=4.6.2#fontawesomeregular') format('svg'); font-weight: normal; font-style: normal;
}

.fa { display: inline-block; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; transition: all .3s ease-in-out; -webkit-transition: all .3s ease-in-out; font-family: 'FontAwesome'; font-style: normal; }
.fa-facebook-square:before { content: "\f082"; }
.fa-twitter-square:before { content: "\f081"; }
.fa-youtube-square:before { content: "\f166"; }
.fa-linkedin-square:before { content: "\f08c"; }
.fa-building:before { content: "\f1ad"; }
.fa-users:before { content: "\f0c0"; }
.fa-clock-o:before { content: "\f017"; }
.fa-angle-right:before{content: "\f105"; }
.fa-eye:before{content: "\f06e";}
.fa-edit:before{content: "\f044";}
.fa-comments:before{content: "\f086";}
.fa-sign-out:before{content: "\f08b";}
.fa-download:before{content: "\f019";}
.fa-heart::before{content: "\f004";}
.fa-calendar::before{content: "\f073";}
.fa-upload::before{content: "\f093";}
.fa-angle-left:before {
    content: "\f104";
}
.fa-bell:before{content: "\f0f3";}
.fa-file-pdf-o:before{content: "\f1c1";}
.fa-refresh:before{content: "\f021";}
.slimmenu li a{ margin: 0px;}
.slimmenu li a.register{ color: #fff;}
.slimmenu li a.btn{margin: 0px;}
header{ top: 20px; position: absolute; left: 0; right: 0; z-index: 99;}
.inner-header{background: #fff; padding: 0px 20px; border-radius: 5px;}
.header-right{margin-top: 0px; text-align: right;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
.logo{margin: 10px 0 0 0px;}
.logo img{width: 150px; height:auto;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
footer{background: #3a3835; line-height: 48px; color: #b9b8b5; }
footer a{ color: #b9b8b5;}
footer .social-icons{ display: inline;}
footer .col-md-6+.col-md-6{ text-align: right;}
footer .social-icons a{ margin-right: 25px;}
footer a:hover{color: #d7af59;}
.image-fill, .image-auto { position: relative; overflow:hidden;}
.image-fill img { width: 100%; height: 100%; -webkit-object-fit: cover; -moz-object-fit: cover; -ms-object-fit: cover; -o-object-fit: cover; object-fit: cover;}
.image-auto img{ max-width: 100%; max-height: 100%; position: absolute; left: 0; top:0; right: 0; bottom: 0; margin: auto;}
.slide-pane__overlay{z-index: 999;}
.slide-pane{width: 50% !important; position: fixed; right:0;}
.slide-pane__title-wrapper{display: none;}
.slide-pane__header{background: none; border-bottom: 0px;}
.slide-pane__close {position: absolute;right: 20px;}
.slide-pane__close:after{content: ""; position: absolute; left: 0px;background: url("../images/close-icon.png")no-repeat center; width: 28px; height: 28px; display: block;}
.slide-pane__close svg{ display: none;}
.login-block{padding: 0px 40px;}
.login-block .form-block{position:relative;}
.error-inva{position:inherit; padding-bottom:30px; font-size:12px;}

.md-form label{top:14px; margin-bottom: 0px; color: #3f434b !important;}
.md-form label.active{-webkit-transform: translateY(-130%);transform: translateY(-130%);font-size:12px; color: #8c929f;}
.md-form{ margin: 0 0 35px 0;}
.md-form label{ font-size: 18px !important;}
.md-form label.active{font-size: 14px !important;}
.form-field input,.form-field select,.form-field textarea{ font-size: 18px; color: #3f434b !important; padding: 0px !important;}
.md-form input:focus:not([readonly]) + label{color: #8c929f !important;}
.md-form input:focus:not([readonly]){ border-bottom: 1px solid #af852b !important;}
.form-block{margin: 20px 0 0 0;}
.form-block span.error{position: absolute; bottom: 15px; font-size:12px; left:15px;}
.vendor-detail{margin-top:20px;}
.form-field .btn{width: 100%;}
.text-center{text-align: center;}
.forgot-block{margin-top: 30px;}
.react-tabs li{ text-align: center; }
.react-tabs li em{font-style: normal;border: 1px solid #726e67; line-height: 45px; border-radius: 5px;font-family: 'PoppinsMedium';font-size: 18px; color: #726e67;position: relative; cursor: pointer; display: block; width: 100%;}
.company{background: url("../images/company_icon.png")no-repeat 0 0;  width: 25px; height: 19px; background-size: 25px auto; display: inline-block; position: relative; top: 3px; margin-right:10px; }
.vendor{background: url("../images/vendor_icon.png")no-repeat 0 0;  width: 19px; height: 24px; background-size: 19px auto; display: inline-block; position: relative; top: 7px; margin-right:10px; }
.react-tabs li.react-tabs__tab--selected em{ background: #e5af4b; border-color: #e5af4b; color: #fff;}
.react-tabs li.react-tabs__tab--selected .company{background-position: 0 -19px;}
.react-tabs li.react-tabs__tab--selected .vendor{background-position: 0 -24px;}
.react-tabs li.react-tabs__tab--selected:after {top: 100%;left: 50%;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;border-color: rgba(136, 183, 213, 0);border-top-color: #e5af4b;border-width: 10px;margin-left: -10px;}
/*.slide-pane.register{width: 65% !important;} */
.react-tabs ul{margin-bottom:60px;}
.avatar-block{width: 150px; height: 150px; margin: 0px auto 70px; position: relative; text-align:center;}
.avatar-block input{opacity: 0; position: absolute; left:0;top:0;right:0;bottom: 0; z-index: 1;}
.avatar-block .hhRbZu{width: 150px; height: 150px; position: relative; overflow: inherit;border: 2px solid #fff;
    -webkit-box-shadow: 0px 0px 16px 0px #ccc;
    box-shadow: 0px 0px 16px 0px #ccc;}
.avatar-block .hhRbZu:after{background: url("../images/upload-camera.png") no-repeat 0 0; position: absolute;right: -10px; bottom: -10px;width: 35px; height: 35px; display: block; content: ""; z-index: 0;}

.avatar-block span{display:inline-block; margin-top: 10px; font-size:16px;}
.search-block{ background: #999999; padding: 20px; margin-top:30px; }
.search-block h6{ color: #fff; text-transform: uppercase;padding: 0px; line-height: 40px;}
.search-form{text-align: right;}
.search-form span{ margin-right:8px; color: #fff; line-height: 40px;}
.search-form select{background: #eceef2; color: #4d4f53; width: 64px; line-height: 40px; margin-right:8px; height: 40px;border-radius: 4px; }
.search-inner{display: inline-block; position: relative; width: 260px;}
.search-inner input{ background: #eceef2; padding-left:10px; width: 260px; line-height: 40px; height: 40px; border-radius: 4px; color: #3f474e; }
.search-inner input.search-icon{ width: 44px; position: absolute; right: 0px; top: 0; font-size: 0px; border:0px;background: url("../images/search-icon.png")no-repeat center; cursor: pointer; }
.white-bg{background: #fff;}
.pad50{padding: 50px 0;}
.btn i.fa-angle-left{margin: 0 10px 0 0;}
.table-header-wrapper table tr th{background: #56607a; color: #fff; border: 1px solid #40495f; border-bottom: 0px; position: relative;}
table.table th, table.table td{padding: 15px 20px 15px 20px;white-space: inherit; word-break: break-word; font-weight: 400; font-size: 14px;}
table.table th:last-child, table.table td:last-child{padding: 15px 0px 15px 20px;}
table.table td{border-right: 1px solid #e3e9f2; position: relative;
    border-bottom: 0px ; border-top: 0px; z-index:9;}

    table.table td:before{content:"";box-shadow: 0px 5px 5px 0px rgba(232,236,240,1);-webkit-box-shadow: 0px 5px 5px 0px rgba(232,236,240,1);-o-box-shadow: 0px 5px 5px 0px rgba(232,236,240,1); position: absolute;
        bottom: 10px;
        width: 100%;
        height: 100%;
        left: 0;z-index:-1;}
table.table{background: #fff;}
.table-header-wrapper table tr th .order{position: absolute; right: 0px; width: 20px;}
.table-header-wrapper table tr th .order .dropdown:after{position: absolute; content: "\f107";font-family: 'FontAwesome'; left:0px; color: #838b9f;}
.table-header-wrapper table tr th .order .caret:after{position: absolute; content: "\f106";font-family: 'FontAwesome'; left:0px; color: #838b9f;}
.table-header-wrapper table tr th .order.dropup .caret:after{content: "\f107";}
.table-header-wrapper table tr th .order .dropdown .caret:after,.table-header-wrapper table tr th .order.dropup:after{content:""}
.table-header-wrapper table tr th .order .dropup .caret:after{content:""}
.page-show{ position: absolute; left:45%; transform: translateX(-50%); top: 10px;}

#pageDropDown{ background: #56607a !important; padding: 7px 12px !important}
.pagination li a{ line-height: 44px; width: 44px; text-align: center; color: #56607a !important; margin: 0 0 0 12px; border-radius: 3px; background: #fff !important; padding: 0px;}
.pagination li.active a,.pagination li:hover a{ background: #d3aa55 !important; color: #fff !important; }
.pagination li a:focus{box-shadow: none !important;}
.react-bs-table-sizePerPage-dropdown .dropdown-menu{ min-width: 100%;}
.react-bs-table-sizePerPage-dropdown .dropdown-item{padding: 0px;}
.react-bs-table-sizePerPage-dropdown .dropdown-item a{ padding: 5px 12px; color: #56607a; display: block;}
.react-bs-table-sizePerPage-dropdown .dropdown-item a:hover{ background: #56607a; color: #fff;}
.react-bs-table-pagination .col-md-6:first-child{position: inherit;}
.react-bs-table-pagination{position: relative;}
.react-bs-table-sizePerPage-dropdown{top:5px;}
.react-bs-table-tool-bar{background: #fff; margin-bottom: 0px; padding: 15px 20px;}
.react-bs-table-search-form input,.react-bs-table-search-form input:focus{ background: #e7e8ea; border-radius: 3px; border-bottom: 0px; padding:0 10px 0 20px; position: relative;}
.react-bs-table-search-form .input-group-btn{position: absolute; right:0; top: 0; width: 50px; height: 50px; display: block; background:#e7e8ea url("../images/search-icon.png")no-repeat center;}
table.table a{ color: #fff;}
table.table a i{margin:0 7px 0 0; font-size: 18px !important;}
.dashboard-rfp-head{ position: absolute; left: 20px; top: 26px; z-index: 9;}
.dashboard-rfp-list{ position: relative;}
.dashboard-rfp-head h6{ display: inline-block;}
.dashboard-rfp-head span{background: url("../images/applied-icon1.png") no-repeat 8px center; padding: 0 7px 4px 35px; margin-left: 30px; font-size: 18px; cursor: pointer; background-size: 25px auto !important;padding: 9px 15px 9px 40px;}
.dashboard-rfp-head span.applied{background:   url("../images/applied-icon1.png") no-repeat 8px center; color: #449d44; cursor: pointer;background-size: 25px auto !important;}
.dashboard-rfp-head span.not-applied{background: url("../images/not-applied-icon.png") no-repeat 8px center; color: #d34946;background-size: 25px auto !important; }
.dashboard-rfp-head span.liked{background: url("../images/like-icon1.png") no-repeat  8px center; color: #30afd4; cursor: pointer;background-size: 25px auto !important;}
.dashboard-rfp-head span.all{background: url("../images/all_img.png") no-repeat  8px center; color: #333b4e; cursor: pointer;background-size: 25px auto !important;}

.action-block a.applied,a.applied em{ width: 30px; height: 30px; display: inline-block;background: #449d44 url("../images/applied-icon.png") no-repeat center center; margin: 0px 10px 7px 0px;border-radius: 3px;}
.action-block a.applied:hover, a.applied em:hover{background: #3a8d3a url("../images/applied-icon.png") no-repeat center center;}
.action-block a.not-applied,a.not-applied em{background: #d34946 url("../images/not-applied-icon.png") no-repeat  center center;}
.action-block a.not-applied:hover,a.not-applied em:hover{background: #c23936 url("../images/not-applied-icon.png") no-repeat  center center;}
.action-block a.liked,a.liked em{ background:#30afd4 url("../images/like-icon.png") no-repeat center center;}
.action-block a.liked:hover,a.liked em:hover{ background:#259bbe url("../images/like-icon.png") no-repeat center center;}
.action-block a.unliked,a.unliked em{background:#999999 url("../images/like-icon.png") no-repeat center center;}
.action-block a.unliked:hover,a.unliked em:hover{background:#585858 url("../images/like-icon.png") no-repeat center center;}
.action-block a{ vertical-align: top;}


.avatar-error{ left:50% !important; -ms-transform: translateX(-50%); /* IE 9 */
    -webkit-transform: translateX(-50%); /* Safari 3-8 */
    transform: translateX(-50%);}

/* .rbt-token {
    background: #57617C;
    margin: 5px 5px 0px 0;
    clear: both;
    display: inline-block;
    padding: 0 0 0 3px;
    border-radius: 3px;
    color: #fff;
    line-height: 23px;
    font-size:18px;
} */
.rbt-token {
    background: #e7e8ea;
    margin: 5px 5px 0px 0;
    clear: both;
    display: inline-block;
    padding: 7px 6px 4px 15px;
    border-radius: 3px;
    color: #404348;
    line-height: 23px;
    font-size: 16px;
}
.rbt-token button.close.rbt-close.rbt-token-remove-button {
    margin: 0 0px 0 5px;
    font-size: 30px;
    position: relative;
    top: 0px;
    right: 0px;
    padding: 0 4px;
    line-height: 25px;
    opacity: 1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #585858;
}
button.close{ opacity: 1;}
/* .rbt-token button.close.rbt-close.rbt-token-remove-button {
    margin: 0 0px 0 5px;
    font-size: 16px;
    position: relative;
    top: 0px;
    right: 0px;
    padding: 0 4px;
    line-height: 23px;
    background: #7b8ba4;
    opacity: 1;
    border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color:#fff;
} */
.form-tags .form-control{ height: auto !important;}
.rbt-aux{position: relative; top: -29px;}
.vendor-detail span{ color: #696c70;}
.vendor-detail h6{ color: #3f434b;}
.vendor-detail h6 a:hover,.vendot-info-detail h6 a:hover{color: #3f434b ;}

.avatar-block img{ width: 90%; height:auto; position:absolute;left:50% ;top:50% ;-ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Safari 3-8 */
    transform: translate(-50%,-50%);}


.header-right .dropdown {
    display: inline-block; vertical-align: top; margin: 0 0 0 15px;padding: 21px 17px; position: relative;  border-top-right-radius: 5px;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out; right:-20px;
}
.header-right .dropdown.notification-active{border-top-right-radius:0;}
.header-right .dropdown__content {
    display: none;
    position: absolute;
    top: 100%;
    left:0;
    right: 0;
    background: #dee3ee;
    border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;
    overflow: hidden;
}
.header-right .dropdown--active{ background: #dee3ee;}
.header-right .dropdown--active .dropdown__content {
    display: block;
}
.header-right .dropdown__content ul li a{ color: #56607a; display: block; text-align: left; line-height: 52px; padding-left: 21px;font-family: 'PoppinsRegular'; border-bottom: 1px solid #cdd2e1;}
.header-right .dropdown__content ul li a:hover{ background: #cdd2e1;}
.header-right .dropdown__content ul li a i{margin-right: 15px;}
.header-right .dropdown__content ul li:last-child a{ border-bottom: 0px;}
.header-right .nav-m{ display: inline-block; margin-top:24px; }
.user-login-avatar{width: 42px; height: 42px; overflow: hidden;  display: inline-block; border:1px solid #ccc; position:relative;}
.user-login-avatar img{width: 98%; height:auto; position:absolute;left:50% ;top:50%;-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);-webkit-transform: translate(-50%,-50%);    }
.dropdown__trigger span{vertical-align: middle; font-family: 'PoppinsRegular';line-height: 15px; padding:0 25px 0 15px; color: #d3aa55; position: relative; display: inline-block;}
.dropdown__trigger span:after{position: absolute; content: "\f107";font-family: 'FontAwesome'; right:0px;top:50%;transform: translateY(-50%);-webkit-transform:translateY(-50%);}
.notification-icon{ display: inline-block; vertical-align: top;  position: relative; }
.notification-icon samp{ position: absolute; left:7px; top: -8px; background: #d3aa55; width: 18px; height: 18px; border-radius: 100%; font-family: 'PoppinsRegular'; color: #fff; font-size: 12px; line-height: 20px; text-align: center;}
.notification-icon i{color: #56607a;}
.DayPickerInput{width: 100%;}
.DayPickerInput input{border:0px; border-bottom: 1px solid #ced4da; height: 50px; background: none;}
.loader-login-div {
    position: absolute;
    z-index: 9;
    top: -1px;
    width: 51px !important;
    left: 25%;
}
.rbt-menu li{text-align: left; font-size: 18px;}
.rbt-menu li a.dropdown-item.active, .rbt-menu li a.dropdown-item:active{background: #57617C;}
.graph-block select,.graph-block input{ border:1px solid #999999; height: 38px !important; line-height: 38px; border-radius: 2px; color: #56607a !important; padding-left: 10px !important; font-size: 16px;}
.graph-block select.form-control:not([size]):not([multiple]){padding:0 30px 0 10px !important}
.graph-block{margin: 40px 0 0 0 ; padding: 40px; background: #fff;}
.graph-field{position:relative; z-index:9;}
.graph-field .fa{position: absolute; top:22px; right:10px;}
.graph-block .graph-field .fa{ top:11px;}
.graph-date{margin-bottom:25px;}
.vendor-logo{width: 100%; height:130px; overflow: hidden; position: relative;}
.vendor-logo img{width: 100%; height: auto; position: absolute; top: 50%; transform: translateY(-50%);-webkit-transform: translateY(-50%)}
.modal-content{padding: 0 50px; border:5px solid #d3aa55 !important ;}
.modal-title{text-align: center; width: 100%; padding-bottom: 10px;}
.modal-header{border-bottom: 0px;}
.modal-header .close{position: absolute; right:15px; top:15px; padding: 10px; background: #56607a;color: #fff;}
.dashboard-rfp-head h6 em{font-style: normal; margin-right:10px; font-size: 16px;}
.view-btn{ background: #d3aa55;color:#fff; line-height: 32px; padding: 0 15px; display: inline-block;font-family: 'PoppinsRegular'; border-radius: 3px; margin: 0 8px 8px 0 !important;}
.view-btn:hover{ background: #af852b;}
.form-field .view-btn{padding: 0 15px !important; width:auto; border:0px; color: #fff !important; cursor: pointer;}
.remove-btn{ background: url("../images/remove-icon.png") no-repeat 0 0;width:24px !important;height:23px;color:#fff; line-height: 32px; padding: 0 ; display: inline-block;margin: 0 !important; background-size: 100% auto;position: absolute; top:0px;right:0px; font-size: 0px !important}
.accolades{position: relative;}
.form-field .remove-btn{ width:auto; border:0px; color: #fff !important; cursor: pointer;}
.sub-btn{ background: #56607a;}
.sub-btn:hover{ background: #45506c;}
.rbt-input-multi{position: relative; }
.rbt-input-multi,.rbt-input-main{height: auto !important;}
.media-links{ margin-bottom: 30px;}
.media-links li{padding: 8px 0; border-bottom: 1px solid #ced4da;}
.media-links li:last-child{border-bottom: 0px;}
.media-links li h6{padding: 0px;}
.view-icon{margin: 0 10px 7px 0 !important;width: 30px; height: 30px; line-height: 34px; text-align: center; background: #d3aa55; color: #fff; border-radius: 3px; display: inline-block;}

.view-icon:hover{background: #af852a;}
.view-icon.edit-icon{ background: #56607a;}
.view-icon.edit-icon:hover{background: #333a4d;}
.view-icon i{margin: 0px !important;}
.view-icon.feedback{ background: #3f434b;}
.view-icon.feedback:hover{ background: #2f3136;}

.view-icon.feedback-taken{ background: #af852a;}
.view-icon.feedback-taken:hover{ background: #af852a;}

.view-icon.fav-icon{ background:#999}
.view-icon.fav-icon:hover{ background:#8b8b8b} 
.view-icon.unfav-icon{ background:#dc71a2;}
.view-icon.unfav-icon:hover{ background:#dc71a2;}
.react-datepicker__input-container{ position: inherit; display: inherit;}
.react-datepicker-wrapper,.react-datepicker__input-container{width: 100%;}
.banner-bg{position: absolute; left:0px; right: 0; top:0px; bottom: 0px; background: rgba(0,0,0,0.5);}
.partners-block{ background: #eef0f2;}
.partner-img{ background: #fff; text-align: center; padding: 15px; margin-bottom: 30px; min-height: 200px; position: relative;}
.partner-img img{width:200px; height: auto; position: absolute; top: 50%;left:50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%);}
.partner-img1 img{width:auto; height:100%;}
.services-block{padding: 80px 0 80px 0;}
.form-field {position: relative;display: inline-block; width: 100%;}
.react-datepicker-wrapper{ display: block !important}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #3f434b !important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #3f434b !important
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #3f434b !important
  }
  .inner-content{ min-height: 400px;}
  .certifications ul li{font-size: 20px;line-height: 20px;font-family: 'PoppinsMedium';
    font-weight: normal;color: #3a3835;padding: 0 0 30px 0;margin: 0;}
  .certifications ul li:last-child span{display: none;}
  .sticky{ position: fixed; width:100%; top: 0px; left:0;-webkit-box-shadow: 0px 3px 12px 0px rgba(204,204,204,0.75);
    -moz-box-shadow: 0px 3px 12px 0px rgba(204,204,204,0.75);
    box-shadow: 0px 3px 12px 0px rgba(204,204,204,0.75); background: #fff;}  
  .naiccodes ul li:last-child span{display: none;}
  .mobile-nav{display:none;}
  .vendor-detail h6 a, .vendot-info-detail h6 a{word-break: break-all; color:#d3aa55;}
  .sticky .header-right .dropdown{padding: 10px 17px;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
  .sticky .dropdown{padding: 10px 23px;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
  .sticky .logo img{width:100px;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
.sticky .header-right .nav-m{margin-top: 10px;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
.sticky .notification-active{padding: 20px 17px 25px 17px !important;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}

.fileupload{position:relative;}.input-field label{color:#3f434b;position:absolute;top:0.8rem;left:0;font-size:1rem;cursor:text;-webkit-transition:.2s ease-out;-moz-transition:.2s ease-out;-o-transition:.2s ease-out;-ms-transition:.2s ease-out;transition:.2s ease-out}.input-field label.active{font-size:0.8rem;-webkit-transform:translateY(-85%);-moz-transform:translateY(-85%);-ms-transform:translateY(-85%);-o-transform:translateY(-85%);transform:translateY(-85%)}.input-field .prefix{position:absolute;width:3rem;font-size:2rem;-webkit-transition:color .2s;-moz-transition:color .2s;-o-transition:color .2s;-ms-transition:color .2s;transition:color .2s}.input-field .prefix.active{color:#26a69a}.input-field .prefix ~ input,.input-field .prefix ~ textarea{margin-left:3rem;width:92%;width:calc(100% - 3rem)}.input-field .prefix ~ textarea{padding-top:.8rem}.input-field .prefix ~ label{margin-left:3rem}@media only screen and (max-width : 992px){.input-field .prefix ~ input{width:86%;width:calc(100% - 3rem)}}@media only screen and (max-width : 600px){.input-field .prefix ~ input{width:80%;width:calc(100% - 3rem)}}.input-field input[type=search]{display:block;line-height:inherit;padding-left:4rem;width:calc(100% - 4rem)}.input-field input[type=search]:focus{background-color:#fff;border:0;box-shadow:none;color:#444}.input-field input[type=search]:focus+label i,.input-field input[type=search]:focus ~ .mdi-navigation-close,.input-field input[type=search]:focus ~ .material-icons{color:#444}.input-field input[type=search]+label{left:1rem}.input-field input[type=search] ~ .mdi-navigation-close,.input-field input[type=search] ~ .material-icons{position:absolute;top:0;right:1rem;color:transparent;cursor:pointer;font-size:2rem;transition:.3s color}

.fileupload .file-path-wrapper{overflow:hidden;padding-left:10px}

.fileupload{position:relative}.file-field .file-path-wrapper{overflow:hidden;padding-left:10px}.file-field input.file-path{width:100%}
.file-field span{cursor: pointer;
    background: #d3aa55;
    padding: 5px 20px;
    position: relative;
    top: -3px;
    color: #fff;}
    .file-field span i{ margin-right:10px;}
    .file-field input[type=file]{position:absolute;top:0;right:0;left:0;bottom:0;width:100%;margin:0;padding:0;font-size:20px;opacity:0;filter:alpha(opacity=0)}
.filebtn{ float: left;}
.fileupload input.file-path {
    width: 100%;
}


/* .vendot-info-detail span,.vendor-detail span{font-size:28px;} */
.vendot-info-detail h6,.vendor-detail h6{font-size:18px;}
.fileupload input[type=text]{
    background-color: transparent;
    border: none;
 
    border-radius: 0;
    outline: none;
    height: auto;
    width: 100%;
    font-size: 18px;
    margin: 0 ;
    padding: 0;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    transition: all .3s;font-size: 14px;
    color: #848484; line-height: 33px;
}

.phoneblock{    position: relative;
    
   }
    .phoneblock input{background-color: transparent;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        border-radius: 0;
        outline: none;
       
        width: 100%;
        font-size: 18px;
        margin: 0 0 8px 0;
        padding: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        -webkit-transition: border .3s, -webkit-box-shadow .3s;
        transition: border .3s, -webkit-box-shadow .3s;
        transition: box-shadow .3s, border .3s;
        transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
    }

    .phoneblock>label {
        color: #9e9e9e;
        position: absolute;
        top: -14px;
        font-size: 14px !important;
        cursor: text;
        -webkit-transition: color .2s ease-out, -webkit-transform .2s ease-out;
        transition: color .2s ease-out, -webkit-transform .2s ease-out;
        transition: transform .2s ease-out, color .2s ease-out;
        transition: transform .2s ease-out, color .2s ease-out, -webkit-transform .2s ease-out;
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        text-align: initial;
       
    }
    .uploadlink a{word-break: break-all;
        font-size: 14px;
        line-height: 15px !important;
        position: relative;
        top: -18px;}
        .mar-10{margin-bottom: 30px;}
        /* .success-msg{ font-size:20px; position: fixed; top: 0; right: 0;left:0px;bottom: 0; background: #ff0000;} */
.react-tabs__tab-panel{position: relative;}
.error-invalid{position: absolute;top:-40px; font-size: 12px;}

.notification-list{width:250px; right:inherit}
.notification-list ul{text-align: left; position: relative;}
.notification-list ul li{padding: 10px 5px;border-bottom: 1px solid #cdd2e1; display: flex;}
.notification-list ul li samp{width: 30px; height:30px; display: inline-block; border-radius: 100%; border:1px solid #ccc; overflow: hidden; min-width: 30px;}
.notification-list ul li samp img{width:30px; height:auto;}
.notification-list ul li a{ vertical-align: top; margin-left:15px; color: #56607a; line-height: 18px !important; padding-left: 0px !important; border-bottom: 0px !important;}
.notification-list ul li:hover{background: #cdd2e1;}
.notification-active{padding: 35px 17px !important;}
.react-bs-table-container{margin: 0 0 40px 0;}
ul.slimmenu.dropmenu{position: relative; right:-21px;}
.likenewClass.liked{background: #32aed4 url("../images/like-icon1.png") no-repeat 8px center !important; color:#fff !important; border-radius: 3px;background-size: 25px auto;}
.applynewClass.applied{background:#449d44 url("../images/applied-icon1.png") no-repeat  8px center !important;color:#fff !important; border-radius: 3px;background-size: 25px auto;}

.allnewClass.all{background:#333b4e url("../images/all_img.png") no-repeat  8px center !important;color:#fff !important; border-radius: 3px;background-size: 25px auto;}
.form-check.fav-block input[type="checkbox"] + label:before, .form-check.fav-block input[type="checkbox"] + label:after{content: "\F004";font-size: 18px;   background: #dc71a2;  color: #fff;  width: 30px;   height: 30px;
    line-height: 30px;border:0px;}
.form-check.fav-block label{ padding:0 8px 0 40px !important; line-height: 30px !important;}
.form-check.fav-block input[type="checkbox"]:checked + label{ background: #dc71a2; color:#fff; border-radius: 3px;}

.form-check.cst-c-block{ margin:0 20px 20px 0; }
.form-check.cst-c-block input[type="checkbox"] + label{background: #e7e8ea;}
.form-check.cst-c-block input[type="checkbox"] + label:before, .form-check.cst-c-block input[type="checkbox"] + label:after{content: "\F004";font-size: 0;  background: #ff0000; color: #fff;  width: 0; height: 0;
    line-height: 30px;border:0px;}
.form-check.cst-c-block label{ padding:14px 20px !important; line-height: 30px !important;}
.form-check.cst-c-block input[type="checkbox"]:checked + label{ background: #56607b; color:#fff;}

.form-check.cst-c-block-SM label {padding: 6px 25px !important; font-size: 14px !important;}
.form-check.cst-c-block-SM input[type="checkbox"] + label { background: #fff;  border: 1px solid #e3e3ea;}
.form-check.cst-c-block-SM { margin: 0 10px 10px 0;}
.form-check.cst-c-block-SM input[type="checkbox"]:checked + label { color: #fff !important;}


/* .dash-stats-list{width:20%; padding: 0 15px; margin: 0px auto;} */
.contact-info span{ display: block; position: relative;padding: 0 0 10px 25px;}
.contact-info span:before{content: "\f041"; position: absolute;font-family: 'FontAwesome'; font-style: normal; font-size: 18px;left:0;}
.contact-info span.phone-no:before{content: "\f095";}
.contact-info span.website:before{content: "\f0ac";}
.contact-info span.website a{color:#d3aa55;}
.contact-info span.website a:hover{ color:#555;}
.cert-doc{padding: 40px;background: #e7e8ea;margin: 20px 0; display: block;}
.cert-doc .fileupload{display: inline-block;width: 50%;}
.cert-doc .file-field span{background: #ccc;color: #555;}
.doc-icon{margin-top:30px; display: inline-block;}
.marg{margin-bottom: 30px;}
.marg h6{padding:0px;}
.filebtn a{ border:1px solid #cdd4e0; background: #e5eaef; padding:0 20px; line-height: 40px; color:#3a3835; display: inline-block; font-weight: bold; font-size:16px; margin:0 10px 0px 0;}
.filebtn a i{margin-right:10px;}
.filebtn a:hover{border: 1px solid #aab1bd;}
.recipe-list li{ font-size:18px;font-family: 'PoppinsMedium'; font-weight:normal; padding:0 0 15px 0; color:#3a3835; width:100%; display: inline-block;}
.rfpviewdetail .dashboard-rfp-list{margin-top:30px;}
.avatar-main{border-bottom:1px solid #cdd4e0; margin-bottom: 40px;}
.avatar-main .avatar-block{ margin:0px auto 40px;}
.cert-upload{ margin:20px 40px 0;}
.fileupload em{font-style:normal; display: inline-block; width:100%;}
.cert-upload .filebtn{ margin-top:10px;}
.carousel-item{height:670px;}
.slide-pane_from_right.ReactModal__Content--after-open{transform: translateX(0px);-moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    }

/* Govind */
#edit_rfp{
float: right !important;
}
#rfp_back{
    float: right !important;
    margin-right: 10px;
}


/* RT 04-10-19 */
.about-block ul{margin: 0 0 20px 30px;}
.about-block ul li{position: relative;padding: 5px 0 5px 30px;}
.about-block ul li::before{content:'\f178';position: absolute;left:0;top:5px;font-family: 'FontAwesome';font-size:18px;color:#d3aa55;}
.forgot-block a{text-transform:uppercase;text-decoration:underline !important;}
.md-form .form-control {padding:0;}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus { -webkit-box-shadow: 0 0 0 30px white inset !important;}
.react-bs-container-header .table tr th:nth-child(2),
.react-bs-container-body .table tr td:nth-child(2){width:300px;}
.react-bs-container-body .table tr:last-child td:before{box-shadow:none;}
.notification-icon i{font-size:18px;}
.back-btn{background:#212121;padding: 15px 25px 15px 45px !important;}
.back-btn:hover{background:#2b465d;}
.back-btn::before{content:"\f177";position:absolute;left:20px;top:15px;font-family: 'FontAwesome';font-size:18px;color:#fff;}
.form-block .btn { min-width: auto;}
.dropdown .dropdown__trigger em{width: 200px;position: relative;height: 45px;display: inline-block; font-style:normal;}
.dropdown .dropdown__trigger em span{text-align: left; position: absolute; top: 50%;left: 0;
    transform: translateY(-50%); font-size:15px;}
.react-bs-table-search-form .input-group-btn {right: 5px;top: 5px;height: 40px;}   
.form-group .form-control:focus {border:2px solid #d3aa55;}
.notification-container{ bottom: 0px !important; top:inherit !important}
.rfpliked{ display: inline-flex; width:110px;}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{width:90px;}
.rbt-input-wrapper input:focus:not([readonly]){border-bottom: 0px !important}
.swal-button--cancel{background: #d34946; color:#fff; border:0px !important}
.swal-button--cancel:hover{background: #af2926 !important; color:#fff;border:0px !important}
.swal-button--danger{    background-color: #449d44 !important; }
.swal-button--danger:hover{    background-color: #337d33 !important; }
.count-btn{ position: relative; margin-right: 18px !important;}
.count-btn span{ position: absolute; width:25px; height: 25px; border-radius:100%; background: #dc71a2;line-height:25px; top:-14px; right:-14px; font-size:10px; text-align: center;}
.count-btn em{margin: 0px !important;}
.applied.count-btn{background: #449d44 !important;}
.applied.count-btn:hover{background: #3a8d3a  !important; color:#fff !important}
.applied.count-btn:hover em{background: #3a8d3a url("../images/applied-icon.png") no-repeat center center;}
.liked.count-btn{background: #30afd4 !important;}
.liked.count-btn:hover{background: #259bbe  !important; color:#fff !important}
.liked.count-btn:hover em{background:#259bbe url("../images/like-icon.png") no-repeat center center;}

.last-width-table table tr th:last-child,.last-width-table table tr td:last-child{width:260px;}
.last-width-table .action-block{padding:5px 0 0 0;}
.video-iframe{ margin-bottom:30px;}

.cst-fcs .rbt .rbt-input-hint-container{ min-height: auto !important;}
.cst-fcs .rbt .rbt-input-hint-container .rbt-input-main{ border: none !important; margin: 15px 10px; padding: 0;}

.postrfp_load {
    margin-left: -64px;
    padding-left: 5px;
}
.companyinfo_load {
    margin-left: -42px;
    padding-left: 5px;
}
.opportunity_load {
    margin-left: -68px;
    padding-left: 5px;
}


/* prasad 08-17-2019 */
.form-field.col-md-12.form-tags  .rbt-menu.dropdown-menu li{position: relative;}
.form-field.col-md-12.form-tags  .rbt-menu.dropdown-menu li::before{content: "";
      position: absolute;
      left: 8px;
      top: 12px;
      width: 6px;
      height: 6px;
      background: #000;
      border-radius: 50%;
}
/* .form-field.col-md-12.form-tags .rbt-menu.dropdown-menu.show{background: #fff; left: -7px !important;} */

.form-field.col-md-12.form-tags .rbt-menu.dropdown-menu.show {
    background: #fff;
    top: 0 !important;
    border: 1px solid #ced4db !important;
}

.form-field.col-md-10.p-0.cst-fcs.form-tags .rbt-menu.dropdown-menu li{position: relative;}

.form-field.col-md-10.p-0.cst-fcs.form-tags .rbt-menu.dropdown-menu li::before{content: "";
    position: absolute;
    left: 8px;
    top: 12px;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
}

.form-field.col-md-10.p-0.cst-fcs.form-tags .rbt-menu.dropdown-menu.show{background: #fff; left: -7px !important;}
/* .form-field.col-md-12.form-tags  .rbt-token-removeable::before{content: "";
position: absolute;
left: -2px;
top: 14px;
width: 6px;
height: 6px;
background: #6b6b6b;
border-radius: 50%;
} */
.md-form.graph-field.postdate-z-index{z-index: auto !important;}
.react-datepicker-popper{z-index: 10 !important;}
/* prasad 08-17-2019 end*/

.vendor-loading{
    margin-left: 130px;
    margin-top: -10px;
}


.form-control.rbt-input-multi{ margin-bottom:0 !important; }
.md-form .form-control{ line-height: 50px !important;margin-bottom:50px;}
.rbt-input-wrapper{  margin-top: 18px;}


.md-form textarea.md-textarea {
    overflow-y: auto;

    line-height: 30px !important;
    resize: vertical; padding-top: 9px !important;
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px;
}

iframe {width:100%;}



/* RT 09-11-19 */
.services-block h4,.about-block h4{padding: 0 0 10px 0;}
.stats-content h2 {font-size: 32px !important;}
.partners-block .content-block{margin-bottom:30px;}

/* RT 09-13-19 */
.extra-accol .accolades span.error {bottom:0;top:50px;left:0;}
.vendot-info-detail h6, .vendor-detail h6 {font-size: 16px;}
.marg{margin-bottom: 20px;}
.recipe-list li {font-size:16px;padding: 0 0 10px;}
.recipe-list li:last-child{padding:0;}

/* RT 09-16-19 */
.ex-title{display: inline-block;}
.marg-top{margin-top:20px;}
.cst-fcs .rbt .rbt-input-hint-container,.rbt .rbt-input-hint-container{height:10px;}
.cst-fcs .rbt .rbt-input-hint-container > div,.rbt .rbt-input-hint-container > div{height:100% !important;width: 100%;}
.cst-fcs .rbt .rbt-input-hint-container input,.rbt .rbt-input-hint-container input{height:20px !important;margin:0 !important;width: 100% !important; position: relative;bottom:20px;}
.com-search-title h6.filter{margin-left:20px;}
.rbt .rbt-input-hint-container {min-height: auto !important;}

/* RT 09-18-19 */
.header-right .dropdown__content ul li{ border-bottom: 1px solid #cdd2e1;padding:10px 0;}
.header-right .dropdown__content ul li a{border: 0;line-height:22px;}

/* RT 09-19-19 */
.header-right .dropdown__content.notification-list ul li {padding:10px 5px;}
.below-error{text-align:center;}
.graph-block input, .graph-block select {padding:0 5px !important;}